import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import Head from '../components/head';
import Layout from '../components/layout';

const NotFound = () => {
  const PAGE = "Not Found";
  return (
    <React.Fragment>
      <Head title={PAGE} />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="hero dark circuit-board">
        <div className="center-stripe">
          <div className="error-code">404</div>
          <div className="malfunction">Page Not Found</div>
          <div className="reboot">
            <Link id="reboot-link" to="/">Reboot</Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NotFound;
